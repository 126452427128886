import { App, Progress, Statistic } from 'antd';
import { NotificationInstance } from 'antd/es/notification/interface';
import { PropsWithChildren, ReactElement, ReactNode, createContext, useCallback, useMemo, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';

const notificationDurationMs = 30 * 1000;

type NotificationKey = Parameters<NotificationInstance['open']>[0]['key'];

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const NotificationContext = createContext<NotificationInstance>(undefined!);

/**
 * Provides an extended notification API.
 *
 * **Provider dependencies:**
 * - (AntD) App
 */
export default function NotificationProvider({ children }: PropsWithChildren): ReactElement {
  const { notification } = App.useApp();

  const desktopNotificationsRef = useRef<Record<string | number | symbol, Notification>>({});

  const destroy = useCallback<NotificationInstance['destroy']>(
    (nullableKey) => {
      const key = getKey(nullableKey);

      notification.destroy(key);
      const desktopNotification = desktopNotificationsRef.current[key as keyof typeof desktopNotificationsRef.current] as Notification | undefined;
      desktopNotification?.close();
      delete desktopNotificationsRef.current[key as keyof typeof desktopNotificationsRef.current];
    },
    [notification],
  );

  const genericOpen = useCallback(
    (openFn: NotificationInstance['open'], args: Parameters<NotificationInstance['open']>[0]) => {
      const key = getKey(args.key);

      if (checkIfShouldSendDesktopNotification()) {
        const desktopNotification = sendDesktopNotification(args.message, args.description, args.key?.toString(), args.onClick);
        desktopNotificationsRef.current[key as keyof typeof desktopNotificationsRef.current] = desktopNotification;
      }

      if (args.role !== 'status') {
        openFn({ ...args, duration: 0 });
      }
      else {
        openFn({
          ...args,
          key,
          duration: 0,
          description: (
            <div>
              <div>{args.description}</div>
              <ProgressBarCountdown onFinish={() => destroy(key)} />
            </div>
          ),
        });
      }
    },
    [destroy],
  );

  const open = useCallback<NotificationInstance['open']>(
    (args) => genericOpen(notification.open, args),
    [genericOpen, notification.open],
  );

  const info = useCallback<NotificationInstance['open']>(
    (args) => genericOpen(notification.info, args),
    [genericOpen, notification.info],
  );

  const success = useCallback<NotificationInstance['open']>(
    (args) => genericOpen(notification.success, args),
    [genericOpen, notification.success],
  );

  const warning = useCallback<NotificationInstance['open']>(
    (args) => genericOpen(notification.warning, args),
    [genericOpen, notification.warning],
  );

  const error = useCallback<NotificationInstance['open']>(
    (args) => genericOpen(notification.error, args),
    [genericOpen, notification.error],
  );

  const value = useMemo<NotificationInstance>(
    () => ({
      open,
      info,
      success,
      warning,
      error,
      destroy,
    }),
    [destroy, error, info, open, success, warning],
  );

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
}

function getKey(key: NotificationKey): NonNullable<NotificationKey> {
  return key ?? `${Date.now()}-${Math.round(Math.random() * 10000)}`;
}

function checkIfShouldSendDesktopNotification(): boolean {
  return Notification.permission === 'granted' && !document.hasFocus();
}

export function sendDesktopNotification(title: ReactNode, content: ReactNode, tag?: string, onClick?: () => void): Notification {
  const notification = new Notification(jsxToString(title), { tag, body: jsxToString(content), icon: '/favicon.svg', renotify: !!tag });

  notification.onclick = (e) => {
    focusThisWindowAndCloseDesktopNotification(e);
    onClick?.();
  };

  return notification;
}

function focusThisWindowAndCloseDesktopNotification(e: Event): void {
  window.focus();
  (e.target as Notification).close();
}

function jsxToString(content: ReactNode): string {
  const jsx = <>{content}</>;

  const htmlStr = ReactDOMServer.renderToString(jsx);
  const span = document.createElement('span');
  span.innerHTML = htmlStr;

  const textContent = span.textContent || span.innerText;
  span.remove();

  return textContent;
}

interface ProgressBarCountdownProps {
  onFinish(): void;
}

function ProgressBarCountdown({ onFinish }: ProgressBarCountdownProps): ReactElement {

  const [seconds, setSeconds] = useState(notificationDurationMs);

  const value = useMemo(
    () => Date.now() + notificationDurationMs,
    [],
  );

  return <>
    <Progress
      showInfo={false}
      size="small"
      percent={seconds / notificationDurationMs * 100}
      style={{ marginBottom: 0 }}
    />
    <Statistic.Countdown value={value} onChange={(ms) => setSeconds(ms as number)} onFinish={onFinish} style={{ display: 'none' }} />
  </>;
}