import { App, theme } from 'antd';
import { PropsWithChildren, ReactElement } from 'react';
import { getAppBarHeight, getAppBarPadding } from '../layouts/AppLayout';

/**
 * Enables the use of the App.useApp() hook.
 *
 * **Provider dependencies:**
 * - ThemeProviders
 */
export default function AntDesignAppProvider({ children }: PropsWithChildren): ReactElement {
  const { token } = theme.useToken();

  return (
    <App
      style={{ height: '100dvh', backgroundColor: token.colorBgContainer }}
      notification={{ top: getAppBarHeight(token) + getAppBarPadding(token) }}
      message={{ top: getAppBarHeight(token) + getAppBarPadding(token) }}
    >
      {children}
    </App>
  );
}