import { GlobalToken } from 'antd';
import { ReactElement } from 'react';
import styled from 'styled-components';
import { spaceSizeToNumber } from '../../utils/antDesignUtils';

export function getAppBarContentHeight(tokens: GlobalToken): number {
  return tokens.controlHeightLG;
}

export function getAppBarPadding(tokens: GlobalToken): number {
  return spaceSizeToNumber(tokens, 'small');
}

export function getAppBarHeight(tokens: GlobalToken): number {
  return getAppBarContentHeight(tokens) + 2 * getAppBarPadding(tokens);
}

const RootContainer = styled.div.attrs({ id: 'aui-AppLayout' }) <{ $isHidden: boolean }>`
  width: 100%;
  height: 100%;
  overflow: hidden;

  display: ${p => p.$isHidden ? 'none' : 'flex'};
  flex-direction: column;
`;

const HeaderContainer = styled.header.attrs({ id: 'aui-Header' })`
  height: ${p => getAppBarHeight(p.theme)}px;
  flex: 0 0 auto;
  padding: ${p => getAppBarPadding(p.theme)}px;

  color: ${p => p.theme.colorWhite};
  background-color: ${p => p.theme.colorPrimary};
`;

const ContentContainer = styled.main.attrs({ id: 'aui-MainContent' })`
  flex: 1 1 auto;
  min-height: 1px;
  background-color: ${p => p.theme.colorBgContainer};
`;

export interface AppLayoutProps {
  header: React.ReactNode;
  content: React.ReactNode;
  isHidden?: boolean;
}

export default function AppLayout({ isHidden, header, content }: AppLayoutProps): ReactElement {

  return (
    <RootContainer $isHidden={isHidden ?? false}>
      <HeaderContainer>
        {header}
      </HeaderContainer>
      <ContentContainer>
        {content}
      </ContentContainer>
    </RootContainer>
  );
}